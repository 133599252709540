<template>
  <div class="onboarding-content-wrapper">
    <div class="onboarding-intro">
      <AppUserAvatar :size="120" />
      <div>
        <h2 class="onboarding-content-title">
          Not normal travel
          <span style="display: inline-block">starts now.</span>
        </h2>
      </div>
      <div>
        <p>Give us a few details.</p>
        <p>
          See trips that'll break you
          <span style="display: inline-block">out of your routine.</span>
        </p>
      </div>
    </div>
    <XButton
      id="submit-login"
      type="large"
      style="margin-top: auto"
      @click="next"
    >
      Start
    </XButton>
  </div>
</template>

<script>
import XButton from '../../components/common/global/XButton.vue'
import AppUserAvatar from '../../components-rf/AppUserAvatar.vue'

export default {
  name: 'OnboardingIntro',
  components: { XButton, AppUserAvatar },
  methods: {
    next() {
      this.$router.push('/onboarding/name')
    },
  },
}
</script>

<style lang="scss" scoped>
.onboarding-intro {
  display: grid;
  justify-items: center;
  gap: 1rem;
  align-self: center;
}
.onboarding-intro-title {
  font-size: 39px;
  font-family: GTSuper;
  line-height: 1.2;
}
</style>
